<template>
  <div>
    <the-mask class="form-control" :placeholder="phoneMask" :mask="phoneMask" v-model="formattedPhone" :disabled="!this.country" />
  </div>
</template>

<script>

import { getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import metadata from 'libphonenumber-js/metadata.full.json'

import { TheMask } from 'vue-the-mask'

export default {
  name: 'PhoneInput',
  props: [
    'country', 'value'
  ],
  components: {
    TheMask
  },
  data () {
    return {
      mask: ''
    }
  },
  mounted () {
    // this.updatePhoneMask()
  },
  computed: {
    formattedPhone: {
      get () {
        return this.value
      },
      set (val) {
        const parsedNumber = parsePhoneNumberFromString(val, this.country, metadata)
        const fullNumber = parsedNumber ? parsedNumber.formatInternational() : val
        this.$emit('input', fullNumber) // Emit the full number with country code to parent
      }
    },
    /**
     * Phone mask
    */
    phoneMask () {
      if (!this.country) return ''
      const exampleNumber = getExampleNumber(this.country, examples, metadata)
      if (exampleNumber) {
        return `+${exampleNumber.countryCallingCode} ${exampleNumber.formatNational().replace(/\d/g, '#')}` // Replace all digits with '#'
      }
      return ''
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
